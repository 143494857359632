<template>
    <div>
        <header class="header text-center">
            <div class="col-xs-9 nav_language">
                <button
                v-for="(item, inde) of langList"
                :key="inde"
                :class="showLangBtnClass(item.lang)"
                @click="changeLang(item.lang)">{{item.title}}</button>
                <!-- <button class="button-active" @click="changeLang('zh-cn')">简体</button> -->
            </div>
            <h1 class="blog-name pt-lg-4 mb-0"><a class="no-text-decoration" href="/">{{$t('UserInfo.name')}}</a></h1>
            
            <nav class="navbar navbar-expand-lg navbar-dark" >
            
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div id="navigation" class="collapse navbar-collapse flex-column" >
                    <div class="profile-section pt-3 pt-lg-0">
                        <img class="profile-image mb-3 rounded-circle mx-auto" src="../assets/images/head.jpg" alt="image" >			
                        
                        <div class="bio mb-3" style="text-align: left;">{{ $t('UserInfo.hi') }}<a href="mailto:249480078@qq.com">249480078@qq.com</a></div><!--//bio-->

                        <hr> 
                    </div><!--//profile-section-->
                    <!-- 左侧导航区域 -->
                    <ul class="navbar-nav flex-column text-start">
                        <li class="nav-item"
                        v-for="(item, index) of navList"
                        :key="index"
                        >
                            <router-link :to="item.path">
                                <span :class="showNavClass(item.path)"><i class="fas fa-home fa-fw me-2"></i>{{ item.title }}</span>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <router-link to="/BlogIndex">
                                <span class="nav-link"><i class="fas fa-bookmark fa-fw me-2"></i>博客文章</span>
                            </router-link>

                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="about.html"><i class="fas fa-user fa-fw me-2"></i>关于博主</a>
                        </li> -->
                    </ul>
                    <!-- 跳转关联网站 -->
                    <!-- <div class="my-2 my-md-3">
                        <a class="btn btn-primary" href="http://manage.zhenbaoer.com">后台入口</a>
                    </div> -->
                    <div></div>
                </div>
            </nav>
        </header>
        <!-- 右侧内容区域 -->
        <div class="main-wrapper">
            <!-- 内容显示再插槽里 -->
            <slot></slot>
            <footer class="footer text-center py-2 theme-bg-dark">
                <small class="copyright">©2024-8<span class="sr-only">    </span><i class="fas fa-heart" style="color: #fb866a;"><a class="btn btn-primary-xs" target="_blank" href="http://manage.zw123.top">{{ $t('HomeView.backstage') }}</a></i> </small>
            
            </footer>
        
        </div><!--//main-wrapper-->
    </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.min.js';
//  import { useI18n } from 'vue-i18n';
export default {
    name: 'LayoutView',
    data() {
        return {
            // currentDate:this.$moment().calendar(),
            lang: '',
            langList: [{
                title: '中文',
                lang:'zh-cn'
            }, {
                title: 'English',
                lang:'en-us'
            }],
            curPath: '/',
            navList:[]
        };
    
    },
    // created() {
    //     // let lang = localStorage.getItem('lang') || 'zh_CN';
	//     // const locale = useI18n( );
    //     // locale.value=lang;
    // },
    // watch: {
    // lang(newValue, old) {
    //     if (newValue != old) {
    //         Moment.locale(this.lang)
    //         console.log('监听了',this.nowDate);
    //         // 注；moment修改语言后需重新渲染（通过双向绑定原理，重新渲染）。
    //         // 但是在这样写就不方便了,因为一个组件中可能会多处使用moment.js,这样都需要重新赋值渲染，
    //         this.currentDate = this.$moment(this.nowDate).calendar()
    //         }
    //     }
    //  },
    methods: {
        initNavList() {
            this.navList=[{
                title: this.$t('Menu.home'),
                path: '/',
                activeNav:true,
            }, {
                title: this.$t('Menu.blog'),
                path: '/BlogIndex',
                activeNav:false,
            },
            {
                title: this.$t('Menu.about'),
                path: '/AboutView',
                activeNav:false,
            },  
            
            ]
        },
        showLangBtnClass(lang) {
            if(lang == localStorage.getItem('lang'))
            {
                 return 'button-active'
            } else {
                return 'button-default '
            }
        },
        showNavClass(path) {
            if(path == this.curPath)
            {
                 return 'nav-link active'
            } else {
                return 'nav-link'
            }
        },
        changeLang(lang) {
            this.$i18n.locale = lang;
            this.lang = lang;
            localStorage.setItem('lang', lang);
            // var navs =this.navList;
            // this.navList=navs;
            this.initNavList();
            // location.reload();
        }
    },
    mounted() {
        this.initNavList();
    }
    // created() {
    //     this.createdLang();
    // }

}
</script>

<style>
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('../assets/css/theme-2.css');
.layout_profile_social
{
    list-style-type: none; /* 移除列表的标记 */
    margin: 0; /* 移除margin */
    padding: 0; /* 移除padding */
    display: flex; /* 使用flex布局 */
   justify-content: center;
}
.layout_profile_social_item
{
    margin-right: 10px; /* 可选的：添加右边距 */
}
.layout_profile_social_item img
{
    height: 16px;

}
.nav_language
{
    display: flex;
    flex-direction: row-reverse;
}
.nav_language .button-default {
    margin:.2rem;
    border: 0;
    border-radius: .3rem;
    background-color:#7ccfdd;

    color: #fff;
    font-size: smaller;
    padding: 0 .5rem;
}
.nav_language .button-active {
    margin:.2rem;
    border: 0;
    border-radius: .3rem;
    background-color:rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: smaller;
    padding: 0 .5rem;
    font-weight: bold;
}

</style>