import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './utils/api' // 导入api接口
import eventBus from './utils/eventBus'
import i18n from './utils/i18n/index'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// createApp(App).use(store).use(router).mount('#app')
const app= createApp(App)
app.use(store)
app.use(router)
 app.use(i18n)
// app.config.globalProperties.$axios=axios
app.mount('#app')

app.config.globalProperties.$api = api;
app.config.globalProperties.$eventBus = eventBus;