export default {
    common:{
        searching:'loading...',
        searched:'no data',
    },
    HtmlTag:{
        sumbit:'Sumbit',
        search:'Go!',
        delete:'Delete',
        keyWord:'keyWord',
    },
    Message:{
        successful:'Successful',
        noNetWork:'No network!',
    },
    UserInfo: {
        name: 'Wayne',
        hi:'Hello. Good to see you? Welcome to my personal site, which records what I think is more important; If it helps you, keep it. Or send me an email at',
    },
    Menu: {
        home:'Home',
        blog:"Blog",
        about:'AboutMe',

    },
    HomeView: {
        heardTitle: 'What is rational is actual and what is actual is rational.',
        subheading:'If you are not brave, you will lose yourself',
        searchInputPlace:'What you want?',
        backstage:'BlogAdmin',

    },
    BlogView:{
        classifyAll:'ClassifyAll',

    },
    BlogDetail: {
        backHome:'Home',
        copyUrl:'CopyURL',
        leaveMessagePanelText:'Is there something you want to say to me? Click here!',
        email:'Email',
        emailInner:'Suggest to fill in the email, I can reply you',
        content:'Content',
        contentInner:'You can enter a maximum of 500 characters',
    },
    abloutMe: {
        wx:'wx',
        xingzuo:'Aquarius',
        chengxuyuan:'Programmer',
        aihao:'Swimming, programming, fishing, online games, travel, food and so on',
        zishu:'The blogger says',
        zishu1:'The current work of the blogger is a code farmer, it is not clear in the future; Front-end and back-end can be developed; The main focus is on back-end development;',
        zishu2:"Code farmer is a blogger's career, programmer is a blogger's hobby, whether engaged in the software industry or not, will continue to play programming, just like fishing, although often air force, but still like",
        zishu3:'Because I like programming, I created this personal site, purely as a personal hobby; Whether you become a code farmer or not, you should keep it up. The articles recorded here will be relatively scattered, mainly in order to give the blogger brain, in order to need, more convenient to find what you want, is an open database of bloggers',
        zishu4:"I didn't think blogging was necessary before. I thought it was narcissistic. I didn't want to be known, so it didn't matter. I now find that I write a blog in order to better myself in certain areas of contact, become more proficient;",
        zishu5:'With all that said, maybe no one will even come in to see it! Ha ha ha ~ Play by yourself ~ happy enough, because here is my brain backup',
        biancheng:'Programming',
        zhuti:'Theme',
        xushu:'Narrate',
        chengjiu:'Achievements',
        shuxichengdu:'Familiarity',
        youxi:'Game',
        mingcheng:'Name',
        duanwei:'Tier',
        xihuanchengdu:'LikingDegree',
        wangzherongyao:'Arena of Valor',
        wangzhe:'NO.1',
        huangjing:'NO.2',
        baiying:'NO.3',
        qingtong:'No.4',
        yingxionglianmeng:'LOL',
        diguoshidai2:'Age2',
        shengqi:'shaiya',
        moshoushijie:'WOW',
        jiejiyouxi:'Arcade game',
        chijiyouxi:'PUBG',
        fankongjingying:'CS1.6',
        ms:'Gourmet',
        cm:'Dish',
        ac:'Like',
        cy:'CulinarySkill',
        xcxcr:'Chili meat',
        ysle:'Goose with salt',
        tcy:'Sweet and sour fish',
        sltds:'ShreddedPotatoes',
        gbjd:'Kung Pao Chicken',
        scy:'Fish with pickled cabbage',
        szrw:'Boiled meatballs',
        hsr:'Braised Pork',
        dy:'Fishing',
        zt:'Theme',
        gl:'Chance',
        zykj:'empty',
        ddjy:'Crucian carp',
        ddxmy:'minnow',
        ddcty:'Ginseng fish',
        ddlycy:'Carp or grass carp',
        ddhsy:'Yellow catfish',
        ddly:'Silver carp',
    }
}