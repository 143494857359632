import { createStore } from 'vuex'


export default createStore({
  state: {
    network: true,
  },
  getters: {
  },
  mutations: {
    // changeNetwork(state, network) {
    //   state.network = network;
    // }
  },
  actions: {
  },
  modules: {
  }
})

