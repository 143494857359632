import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/HomeIndex'


const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/BlogIndex',
    name: 'BlogIndex',
    component: () => import('../views/blog/index/BlogIndex')
  },
  {
    path: '/BlogDetail',
    name: 'BlogDetail',
    component: () => import('../views/blog/detail/BlogDetail')
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    component: () => import('../views/about/AboutView')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
