export default {
    common:{
        searching:'正在加载中',
        searched:'见底了，没有数据了',
    },
    HtmlTag:{
        sumbit:'提交',
        search:'检索',
        delete:'删除',
        keyWord:'请输入关键字',
    },
    Message:{
        successful:'提交成功',
        noNetWork:'无网络!',
    },
    UserInfo: {
        name: '甄伟',
        hi:'嗨~ 吃了吗？欢迎来到我的个人站点，这里记录着我这觉得比较重要的东西；如果对你也有帮助，那就收藏起来吧！想和我交流，可以发邮件到这里：',
    },
    Menu: {
        home:'博客首页',
        blog:"博客文章",
        about:'关于我',

    },
    HomeView: {
        heardTitle: '存在即是合理',
        subheading:'不去面对现实，则无法看清自己',
        searchInputPlace:'想你之所要?',
        backstage:'后台入口',

    },
    BlogView:{
        classifyAll:'全部分类',

    },
    BlogDetail: {
        backHome:'首页',
        copyUrl:'复制链接',
        leaveMessagePanelText:'您是不是有什么话想对我说？可点击这里!',
        email:'邮箱',
        emailInner:'建议填邮箱，以方便回复你的见解；你若是不开心可以不填',
        content:'内容',
        contentInner:'最多可以输入500个字符',
    },
    abloutMe: {
        wx:'微信号',
        xingzuo:'水瓶座',
        chengxuyuan:'程序员',
        aihao:'游泳、编程、钓鱼、网游、旅游、美食等等',
        zishu:'博主自述',
        zishu1:'博主本人目前的本职工作是一位码农，以后不清楚；前端后端都能开发；主要精力还是研究后端开发；',
        zishu2:'码农是博主的职业，程序员是博主爱好，以后无论是否从事软件行业，都会继续玩编程，就像自己钓鱼一样，虽然经常空军，但还是喜欢',
        zishu3:'因为喜欢编程，所以创建此个人站点，纯属个人爱好；无论以后做不做码农，应该都会保持下去。这里记录的文章会比较散，主要是为了给博主补脑子，为了需要的时候，更加方便的找到想要的，算是博主的公开数据库',
        zishu4:'以前没觉得写博客有必要，觉得挺自恋的行为，因为自己不想被别人知道，也就无所谓了；现在发现写博客是为了更好让自己在某些接触过的领域，变得更加精通；',
        zishu5:'说了这么多，也许根本就不会有人进来看！哈哈哈~自己玩~自己开心就足以，因为这里是我的脑子的备份',
        biancheng:'编程',
        zhuti:'主题',
        xushu:'叙述',
        chengjiu:'成就',
        shuxichengdu:'熟悉程度',
        youxi:'游戏',
        mingcheng:'名称',
        duanwei:'段位',
        xihuanchengdu:'喜欢程度',
        wangzherongyao:'王者荣耀',
        wangzhe:'王者',
        huangjing:'黄金',
        baiying:'白银',
        qingtong:'青铜',
        yingxionglianmeng:'英雄联盟',
        diguoshidai2:'帝国时代2',
        shengqi:'神泣',
        moshoushijie:'魔兽世界',
        jiejiyouxi:'街机游戏',
        chijiyouxi:'绝地求生',
        fankongjingying:'反恐精英',
        ms:'美食',
        cm:'菜名',
        ac:'爱吃',
        cy:'厨艺',
        xcxcr:'湘菜-小炒肉',
        ysle:'盐水卤鹅',
        tcy:'糖醋鱼',
        sltds:'酸辣土豆丝',
        gbjd:'宫保鸡丁',
        scy:'酸菜鱼',
        szrw:'水煮肉丸',
        hsr:'红烧肉',
        dy:'钓鱼',
        zt:'主题',
        gl:'概率',
        zykj:'遭遇空军',
        ddjy:'钓到鲫鱼',
        ddxmy:'钓到小毛鱼',
        ddcty:'钓到参条鱼',
        ddlycy:'钓到鲤鱼/草鱼',
        ddhsy:'钓到黄颡鱼',
        ddly:'钓到鲢鱼',
    }
}