<template>
    <layout-view>
        <section class="cta-section theme-bg-light py-5">
                <div class="container text-center single-col-max-width">
                    <h2 class="heading">{{ $t('HomeView.heardTitle') }}</h2>
                    <div class="intro">{{ $t('HomeView.subheading') }}</div>
                    <div class="single-form-max-width pt-3 mx-auto">
                        <form class="signup-form row g-2 g-lg-2 align-items-center">
                            <div class="col-12 col-md-9">
                                <input type="text" v-model="keyWord" class="form-control me-md-1 semail" :placeholder="$t('HomeView.searchInputPlace')">
                            </div>
                            <div class="col-12 col-md-2">
                                <button type="button" @click="submitQueary" class="btn btn-primary">{{ $t('HtmlTag.search')  }}</button>
                            </div>
                        </form><!--//signup-form-->
                    </div><!--//single-form-max-width-->
                </div><!--//container-->
        </section>
            
            
        <section class="blog-list px-3 py-5 p-md-5">
            <div class="container single-col-max-width">

                <div class="item mb-5"
                v-for="item of classifyList"
                :key="item.name"
                >
                    <div class="row g-3 g-xl-0">
                        <div class="col-2 col-xl-3">
                            <img class="img-fluid post-thumb " :src="item.imgUrl" alt="image">
                        </div>
                        <div class="col">
                            <router-link target="_blank" :to="{ name : 'BlogDetail',query: { id : item.wayne_Blog.id }}">
                                <h3 class="title mb-1"><span class="text-link" >{{ item.wayne_Blog.title }}</span></h3>
                            </router-link>
                            <div class="meta mb-1"><span class="date">{{ item.wayne_Blog.created }}</span><span class="time">{{ item.name }}</span><span class="comment"><a class="text-link" href="#">{{ item.wayne_Blog.readCount }}</a></span></div>
                            <div class="intro">
                                <div>{{ item.wayne_Blog.describe }}</div>
                                <div>{{ item.wayne_Blog.mainBodyShort }}</div>
                            </div>
                         <router-link :to="{ path : '/BlogIndex',query: { keyWord : '',classify : item.name }}">
                            <span class="text-link">{{ item.name }} [{{ item.total }}] &rarr;</span>
                         </router-link>
                         
                        </div><!--//col-->
                    </div><!--//row-->
                </div><!--//item-->

                
                
                <!-- <nav class="blog-nav nav nav-justified my-5">
                <a class="nav-link-prev nav-item nav-link d-none rounded-left" href="#">Previous<i class="arrow-prev fas fa-long-arrow-alt-left"></i></a>
                <a class="nav-link-next nav-item nav-link rounded" href="#">Next<i class="arrow-next fas fa-long-arrow-alt-right"></i></a>
                </nav> -->
                
            </div>
        </section>
    </layout-view>
</template>

<script>
import LayoutView from '@/components/LayoutView.vue'
export default {
    name: 'HomeIndex',
    components: {
        LayoutView,
    },
    data() {
        return {
            keyWord:'',
            classifyList:[],
        };
    },
    methods: {
        getClassifyInfo() {
            this.$api.WayneBlog.BlogClassifyTotal({
                dictionaryId:'011900a9-3027-4cd6-9d6d-2ed92474cd1e',
                author: '甄伟'
            }).then(res => {
                console.log(res.data);
                this.classifyList = res.data;
            });
         },
         submitQueary() {
            this.$router.push({
                path: '/BlogIndex',
                query: {
                    keyWord : this.keyWord,
                    classify : '',
                }
            });
         }
         
    },
    mounted() {
        this.getClassifyInfo();
    }
}
</script>