import axios from 'axios'
import router from '@/router'
import store from '@/store';

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  alert(msg);
}
/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
      path: '/login',
      query: {
          redirect: router.currentRoute.fullPath
      }
  });
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
      // 401: 未登录状态，跳转登录页
      case 401:
          toLogin();
          break;
      // 403 token过期
      // 清除token并跳转登录页
      case 403:
          if(confirm('你的身份令牌验证失败或过期，请重新登录')){
            localStorage.removeItem("token");
            localStorage.removeItem("tokenRefreshTime");
            location.reload();
            toLogin();
          }
          // tip('登录过期，请重新登录');
          // localStorage.removeItem('token');
          // store.commit('loginSuccess', null);
          // setTimeout(() => {
          //     toLogin();
          // }, 1000);
          break;
      // 404请求不存在
      case 404:
          tip('请求的资源不存在');
          break;
      default:
          // tip(status);
          console.log(other);
}}
// 创建axios实例
var instance = axios.create({    timeout: 20000 * 12});

// post请求头
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
      // 登录流程控制中，根据本地是否存在token判断用户的登录情况 
      // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
      // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
      // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作
      // const token = store.state.token;
      // token && (config.headers.Authorization = token);
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      return config;
  },    
  error => Promise.error(error)
  )
// 响应拦截器
instance.interceptors.response.use(    
  //请求成功
  res => {
     if(res.status === 200 || res.status === 201 ||res.status === 204 ) {
      return Promise.resolve(res);
     }
     else {
      return Promise.reject(res);
    }
      //对响应数据进行预处理
      // switch(res.status)
      // {
      //     case 200:
      //         Promise.resolve(res) 
      //         break;
      //     case 201:
      //       Promise.resolve(res)
      //         break;
      //     case 204:
      //         Promise.resolve(res) 
      //         break;
      //     default:
      //         Promise.reject(res)
      //         break;
      // }
      // return;
  },
  // res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),    
  // 请求失败
  error => {
      const { response } = error;
      if (response) {
          // 请求已发出，但是不在2xx的范围
          errorHandle(response.status, response.data.message);
          return Promise.reject(response);
      } else {
          // 处理断网的情况
          // eg:请求超时或断网时，更新state的network状态
          // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
          // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
          if (!window.navigator.onLine) {
             store.commit('changeNetwork', false);
          } else {
              return Promise.reject(error);
          }
      }
  });
  export default instance;


// class AxiosService {
//     constructor() {
//         this.axiosInstance = axios.create({
//           baseURL: 'http://localhost:5254', // 替换为您的基础 URL
//           timeout: 5000 // 设置超时时间
//         });
//     // 请求拦截器
//     axios.interceptors.request.use(
//       config => {
//         // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了  
//         const token = localStorage.getItem('token');
//         if (token) {
//           config.headers.Authorization = `Bearer ${token}`;
//         }
//         // if(config.method.toUpperCase() === 'POST') {
//         //   config.headers['Content-Type'] = 'application/json;charset=utf-8'
//         // }
//         return config
//       },
//       error => {
//         return Promise.reject(error)
//       })
    
//     // 响应拦截器
//     axios.interceptors.response.use(
//         response => {
//             // 对响应数据进行预处理
//             // console.log(res)
//             switch(response.status)
//             {
//                 case 200:
//                     return response.data;
//                     break;
//                 case 201:
//                     return response.data;
//                     break;
//                 case 204:
//                     return response.data;
//                     break;
//                 default:
//                     throw new error(`服务器响应状态码: ${response.status}`);
//             }
//             // const back_data = res.data
//             // //未登录
//             // if (back_data?.code === 20000){
//             //     router.push('/login')
//             // }else {  
//             //     ElMessage({
//             //         message: back_data.msg || res.message  ,
//             //         type: 'error',
//             //     })
//             // }
//             // return Promise.resolve(res);
//         }, 
//         error => {
//             switch (error.status || error.response.status) {
//             case 500: 
//                 alert('error:500,API服务器异常');  
//                 break
//             case 404:
//                 alert('error:404,找不到api');  
//                 break
//             case 403:
//                 alert('你不存拥有此项操作权限，请找管理员开通相关权限');  
//                 break
//             case 400:
//                 alert(error.response.data);  
//                 // ElMessage({
//                 //     message: 'sorry, 400!',
//                 //     type: 'error',
//                 // })
//                 break
//             case 401:
//                 if(confirm('你的身份令牌验证失败或过期，请重新登录')){
//                     localStorage.removeItem("token");         
//                     localStorage.removeItem("tokenRefreshTime");    
//                     location.reload();
//                   }
//                   router.push('/login')
//                 break
//             // 其他错误，直接抛出错误提示
//             default:
//                 alert(error.message || error.msg);
//                 break;
//                 // ElMessage({
//                 //     message: error.message || error.msg,
//                 //     type: 'error',
//                 // })
//             }
//             return Promise.reject(error);
//         }
//     );
    
//      // GET 请求方法
//      get(url, params = {}) {
//         return this.axiosInstance.get(url, { params });
//       };
//       // POST 请求方法
//       post(url, data = {}) {
//         return this.axiosInstance.post(url, data);
//       };
    
//       // PUT 请求方法
//       put(url, data = {}) {
//         return this.axiosInstance.put(url, data);
//       };
    
//       // DELETE 请求方法
//       delete(url, params = {}) {
//         return this.axiosInstance.delete(url, { params });
//       };    
// }
// export default new AxiosService();
