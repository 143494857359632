/**
 * article模块接口列表
 */
import base from '@/utils/api/base'; // 导入接口域名列表
import axios from '@/utils/http'; // 导入http中创建的axios实例
// import QS from 'qs';
// import qs from 'qs'; // 根据需求是否导入qs模块
const WayneBlog = {
		// 首页博客列表
		WayneBlogList (params) {
			return axios.get(`${base.zhenbaoerManage}/api/Wayne_Blog`, {
				params:params,
			});
		},
		// 博客详情
		WayneBlogDetail (id, params) {
			return axios.get(`${base.zhenbaoerManage}/api/Wayne_Blog/${id}`, {
				params: params,
			});
		},
		// 博客详情阅读增长
		WayneBlogDetailRead (id, params) {
			return axios.post(`${base.zhenbaoerManage}/api/Wayne_Blog/${id}`,
			params
			);
		},	
		// 统计博客分类
		BlogClassifyTotal (params) {
			return axios.get(`${base.zhenbaoerManage}/api/Wayne_Blog/BlogClassifyTotal`, {
				params: params,
			});	
		},
		// 博客分类
		BlogClassify() {
			return axios.get(`${base.zhenbaoerManage}/api/System_Dictionary/FristDictionarys/011900a9-3027-4cd6-9d6d-2ed92474cd1e`);	
		},
		//博客留言
		Blog_AddMessage (id, params) {
			return axios.post(`${base.zhenbaoerManage}/api/Wayne_Blog/${id}/LeaveMessage`, 
				params );
		},
		//博客留言列表
		Blog_MessageList (id, params) {
			return axios.get(`${base.zhenbaoerManage}/api/Wayne_Blog/${id}/LeaveMessage`, {
					params: params,
				});
		},
		//博客留言列表
		Blog_Message_Delete (id, itemId,params) {
			return axios.delete(`${base.zhenbaoerManage}/api/Wayne_Blog/${id}/LeaveMessage/${itemId}`, {
					params: params,
				});
			},		
    // // post提交
	// login (params) {
	// 	return axios.post(`${base.sq}/accesstoken`, qs.stringify(params));
	// }
}
export default WayneBlog;
