import { createI18n } from 'vue-i18n'
import zhCN from './locale/cn'
import enUS from './locale/en'

let b_lang=navigator.language.toLowerCase();
console.log('b_lang:'+b_lang);
let curlang ='zh-cn';
let lang = localStorage.getItem('lang');
if(lang) {
  curlang = lang;
} else if(b_lang == 'en-us' )
{
   curlang = 'en-us'
}
localStorage.setItem('lang', curlang);
const i18n = createI18n({
  legacy: false,
  locale:  curlang,
  messages: {
    'zh-cn': zhCN,
    'en-us': enUS
  }
})

export default i18n